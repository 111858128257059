import React from "react";
import {useAuth} from "../pages/AuthProvider";

interface RequireLoginProps {
    children: React.ReactNode;
}

const RequireLogin: React.FC<RequireLoginProps> = ({ children }) => {
    const auth = useAuth();

  return (
    <>
        {auth.isLoggedIn ? children : <div style={
            {
                margin: '30px',
            }}>
            <h3>You must be logged in to view this page</h3>
        </div>}
    </>
  );
};

export default RequireLogin;
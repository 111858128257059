import ButtonItem from './ButtonItem';
import { useEffect, useState } from 'react';
import { PopupModal } from './PopupModal';
import { GroupAction, GroupLinkedRole, GroupRole, Role } from '../api';
import { OptionType } from './GuestUsersPicker';
import Select, { ActionMeta, OnChangeValue, SingleValue } from 'react-select';
import { groupsApi } from '../api/helpers/groups-api';
import { darkThemeSelect } from './Styles';
import './GroupRolePicker.css';
import { Button } from '@mui/material';
import { CustomButton } from './CustomButton';

export const GroupRolePicker = ({ guildId }: GroupRolePickerProps) => {
    const [show, setShow] = useState(false);
    const [groupRoles, setGroupRoles] = useState<GroupRole[]>([]);
    const [guildRoles, setGuildRoles] = useState<Role[]>([]);
    const [groupLinkedRoles, setGroupLinkedRoles] = useState<GroupLinkedRole[]>([]);
    const [everyoneRole, setEveryoneRole] = useState<GroupLinkedRole>({
        id: '',
        discordRoleId: '',
        groupRoleId: '',
        action: GroupAction.None,
    });

    useEffect(() => {
        if (!show) {
            return;
        }
        groupsApi.getGroupRoles(guildId).then((response) => {
            setGroupRoles(response.data.groupRoles);
            setGroupLinkedRoles(response.data.linkedGroupRoles);
            setGuildRoles(response.data.guildRoles);
            setEveryoneRole(response.data.everyoneRole);
        });
    }, [guildId, show]);

    function updateGroupRole(id: string, discord_role?: string, vrchat_role?: string, action?: GroupAction) {
        if (everyoneRole.id === id) {
            setEveryoneRole((prevEveryoneRole) => {
                return {
                    ...prevEveryoneRole,
                    action: action ?? GroupAction.None,
                };
            });
        } else {
            setGroupLinkedRoles((prevGroupLinkedRoles) => {
                return prevGroupLinkedRoles.map((groupLinkedRole) => {
                    if (groupLinkedRole.id === id) {
                        return {
                            ...groupLinkedRole,
                            discordRoleId: discord_role === '@everyone' ? everyoneRole.discordRoleId : discord_role,
                            groupRoleId: vrchat_role,
                            action: action ?? GroupAction.None,
                        };
                    }
                    return groupLinkedRole;
                });
            });
        }

        groupsApi
            .updateGroupRole(guildId, {
                discordRoleId: discord_role === '@everyone' ? everyoneRole.discordRoleId : discord_role,
                groupRoleId: vrchat_role,
                action: action ?? GroupAction.None,
                id: id,
            })
            .then(() => {
                // Success
            });
    }
    const GroupRoleRow = ({ index, id, discord_role, vrchat_role, action }: GroupRoleRowProps) => {
        const groupRoleOptions: OptionType[] = groupRoles.map((role) => {
            return {
                value: role.id,
                label: role.name,
            };
        });

        const guildRoleOptions: OptionType[] = guildRoles.map((role) => {
            return {
                value: role.id,
                label: role.name,
            };
        });

        const actionOptions: OptionType[] = [
            { value: GroupAction.None, label: 'None' },
            { value: GroupAction.Membership, label: 'Membership' },
            { value: GroupAction.NoMembership, label: 'No Membership' },
            { value: GroupAction.GrantRolePermanent, label: 'Grant Role Permanent' },
            { value: GroupAction.GrantRoleTemporary, label: 'Grant Role Temporary' },
            { value: GroupAction.RevokeRole, label: 'Revoke Role' },
        ];

        const actionOptionsEveryoneRole: OptionType[] = [
            { value: GroupAction.None, label: 'None' },
            { value: GroupAction.Membership, label: 'Membership' },
        ];

        const handleDiscordRoleChange = (value: SingleValue<OptionType>, meta: ActionMeta<OptionType>) => {
            if (!value) {
                return;
            }
            const role = value.value;
            updateGroupRole(id, role, vrchat_role ?? undefined, action);
        };
        const handleVrchatRoleChange = (value: OnChangeValue<OptionType, false>, meta: ActionMeta<OptionType>) => {
            if (!value) {
                return;
            }
            const role = value.value;
            updateGroupRole(id, discord_role ?? undefined, role, action);
        };
        const handleActionChange = (value: OnChangeValue<OptionType, false>, meta: ActionMeta<OptionType>) => {
            if (!value) {
                return;
            }
            const action = value.value as GroupAction;

            updateGroupRole(id, discord_role ?? undefined, vrchat_role ?? undefined, action);
        };

        return (
            <tr className="group-role-row">
                <td>{index}</td>
                <td>
                    {discord_role === '@everyone' ? (
                        <div className={'fit-width'}>
                            <b>@everyone</b>
                        </div>
                    ) : (
                        <Select
                            options={guildRoleOptions}
                            value={guildRoleOptions.find((option) => option.value === discord_role)}
                            onChange={handleDiscordRoleChange!}
                            styles={darkThemeSelect}
                            isMulti={false}
                        />
                    )}
                </td>
                <td>
                    {action === GroupAction.GrantRolePermanent ||
                    action === GroupAction.GrantRoleTemporary ||
                    action === GroupAction.RevokeRole ? (
                        <Select
                            options={groupRoleOptions}
                            value={groupRoleOptions.find((option) => option.value === vrchat_role)}
                            onChange={handleVrchatRoleChange!}
                            styles={darkThemeSelect}
                            isMulti={false}
                        />
                    ) : null}
                </td>
                <td>
                    {discord_role === '@everyone' ? (
                        <Select
                            options={actionOptionsEveryoneRole}
                            value={actionOptionsEveryoneRole.find((option) => option.value === action)}
                            onChange={handleActionChange}
                            styles={darkThemeSelect}
                            isMulti={false}
                        />
                    ) : (
                        <Select
                            options={actionOptions}
                            value={actionOptions.find((option) => option.value === action)}
                            onChange={handleActionChange}
                            styles={darkThemeSelect}
                            isMulti={false}
                        />
                    )}
                </td>
                <td>
                    {discord_role === '@everyone' ? null : (
                        <CustomButton
                            name={'X'}
                            className={'btn-danger'}
                            onClick={() => {
                                groupsApi
                                    .deleteGroupRole(guildId, id)
                                    .then(() => {
                                        setGroupLinkedRoles((prevGroupLinkedRoles) => {
                                            return prevGroupLinkedRoles.filter(
                                                (groupLinkedRole) => groupLinkedRole.id !== id
                                            );
                                        });
                                    })
                                    .catch((error) => {
                                        if (error.response.status !== 404 && error.response.status !== 400) {
                                            console.error(error);
                                            alert('Failed to delete group role');
                                        }
                                    });
                            }}
                        />
                    )}
                </td>
            </tr>
        );
    };

    return (
        <div>
            <ButtonItem name={'Group Roles'} buttonName={'Setup'} onClick={() => setShow(true)} />
            <PopupModal show={show} onClose={() => setShow(false)} title={'Group Roles'}>
                <table className={'group-role-picker table table-dark table-hover'}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Discord</th>
                            <th>VRChat</th>
                            <th>Actions</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <GroupRoleRow
                            index={0}
                            id={everyoneRole.id}
                            action={everyoneRole.action ?? GroupAction.None}
                            discord_role={'@everyone'}
                        />
                        {groupLinkedRoles.map((groupLinkedRole, index) => (
                            <GroupRoleRow
                                index={index + 1}
                                id={groupLinkedRole.id}
                                discord_role={groupLinkedRole.discordRoleId}
                                vrchat_role={groupLinkedRole.groupRoleId}
                                action={groupLinkedRole.action ?? GroupAction.None}
                            />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={4} />
                            <td colSpan={1}>
                                <CustomButton
                                    name={'Add'}
                                    className={'btn-primary'}
                                    onClick={() => {
                                        groupsApi.createGroupRole(guildId).then((response) => {
                                            setGroupLinkedRoles((prevGroupLinkedRoles) => {
                                                return [
                                                    ...prevGroupLinkedRoles,
                                                    {
                                                        id: response.data.id,
                                                        discordRoleId: '',
                                                        groupRoleId: '',
                                                        action: GroupAction.None,
                                                    },
                                                ];
                                            });
                                        });
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </PopupModal>
        </div>
    );
};

interface GroupRoleRowProps {
    index: number;
    id: string;
    discord_role?: string | undefined | null;
    vrchat_role?: string | undefined | null;
    action: GroupAction;
}

interface GroupRolePickerProps {
    guildId: string;
}

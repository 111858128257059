// components/VRChatGroupLinking.tsx
import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import InputFieldItem from './InputFieldItem';
import { groupsApi } from '../api/helpers/groups-api';
import { LinkStatus, MemberStatus, StartLinkGroupResponse } from '../api';
import './VRChatGroupLinking.css';
import { PopupModal } from './PopupModal';
import { UrlButton } from './UrlButton';

const group_regex = new RegExp('grp_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}');

interface VRCGroupLinkingProps {
    guildId: string;
    onFinished: () => void;
}
export const VRChatGroupLinking: React.FC<VRCGroupLinkingProps> = ({ guildId, onFinished }) => {
    const [groupId, setGroupId] = React.useState<string>('');
    const [linkingPhase, setLinkingPhase] = React.useState<number>(0);

    const setGroupIdInternal = (value: string) => {
        const match = group_regex.exec(value);
        if (match) {
            setGroupId(match[0]);
        } else {
            setGroupId(value);
        }
    };
    const startLinking = () => {
        setLinkingPhase(1);
    };

    switch (linkingPhase) {
        case 0:
            return (
                <>
                    <LinkingPhase1 groupId={groupId} setGroupId={setGroupIdInternal} startLinking={startLinking} />
                </>
            );
        case 1:
            return (
                <>
                    <LinkingPhase2
                        guildId={guildId}
                        groupId={groupId}
                        setLinkingPhase={setLinkingPhase}
                        onFinished={onFinished}
                    />
                </>
            );
        default:
            return <></>;
    }
};

interface LinkingPhase1Props {
    groupId: string;
    setGroupId: (groupId: string) => void;
    startLinking: () => void;
}

const LinkingPhase1: React.FC<LinkingPhase1Props> = ({ groupId, setGroupId, startLinking }) => {
    return (
        <div className="server-toggles list-group">
            <h1>Group not Linked</h1>
            <InputFieldItem
                name={'Group ID'}
                onChange={(value) => {
                    setGroupId(value);
                }}
                value={groupId}
                placeholder={'Enter ID'}
            />
            <div>
                <Button
                    variant="contained"
                    style={{ color: 'white', textTransform: 'none' }}
                    disabled={!group_regex.test(groupId)}
                    onClick={() => {
                        startLinking();
                    }}
                >
                    Start Linking Group
                </Button>
            </div>
        </div>
    );
};

interface LinkingPhase2Props {
    groupId: string;
    guildId: string;
    setLinkingPhase: (phase: number) => void;
    onFinished: () => void;
}

const LinkingPhase2: React.FC<LinkingPhase2Props> = ({ groupId, guildId, setLinkingPhase, onFinished }) => {
    const [groupDetails, setGroupDetails] = React.useState<StartLinkGroupResponse>();
    const [linkStatus, setLinkStatus] = React.useState<LinkStatus>(LinkStatus.CodeNotFound);
    const [showLinkingConfirmation, setShowLinkingConfirmation] = React.useState(false);

    useEffect(() => {
        // Here we fetch the group details and start the linking process on the backend
        groupsApi
            .linkGroup(guildId, { groupId: groupId })
            .then((response) => {
                setGroupDetails(response.data);
            })
            .catch((error) => {
                if (error) {
                    // Group not found redirect to phase 1 and pop up an error
                    setGroupDetails(undefined);
                    setLinkingPhase(0);
                    console.error(error);
                }
            });
    }, [groupId, guildId, setLinkingPhase]);

    return (
        <div>
            {groupDetails ? (
                <div className={'group-link'}>
                    <h1>VRChat Group Linking</h1>
                    <br />
                    <h1>{groupDetails.name}</h1>
                    <img src={groupDetails.iconUrl ?? undefined} alt={'Group Icon'} />
                    <br />
                    <br />

                    <div>
                        <h2 className={'link-message'}>
                            To link your group you must enter the following code into the group's description. You can
                            remove the code after the group has been linked
                        </h2>
                        <h2 className={'link-code'}>{groupDetails.linkCode}</h2>
                    </div>

                    <div className="data-urls">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                window.open('https://vrchat.com/home/group/' + groupDetails.id);
                            }}
                        >
                            Open Group Page
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                groupsApi
                                    .linkGroupCheck(guildId, {
                                        groupId: groupId,
                                    })
                                    .then((response) => {
                                        const data = response.data;
                                        setLinkStatus(data.status);
                                        setShowLinkingConfirmation(true);
                                    });
                            }}
                        >
                            Check Code
                        </button>
                    </div>
                </div>
            ) : (
                <div>Loading...</div>
            )}

            <LinkingConfirmationModal
                show={showLinkingConfirmation}
                onClose={() => setShowLinkingConfirmation(false)}
                linkStatus={linkStatus}
                groupDetails={groupDetails}
                guildId={guildId}
                onFinished={onFinished}
            />
        </div>
    );
};

// @ts-ignore
const LinkingConfirmationModal: React.FC<{
    show: boolean;
    onClose: () => void;
    linkStatus: LinkStatus;
    groupDetails: StartLinkGroupResponse | undefined;
    guildId: string;
    onFinished: () => void;
}> = ({ show, onClose, linkStatus, groupDetails, guildId, onFinished }) => {
    const [currentStatus, setCurrentStatus] = React.useState<MemberStatus>(MemberStatus.Unknown);

    return (
        <PopupModal show={show} onClose={onClose} title={'VRChat Group Linking'}>
            <div>
                {linkStatus === LinkStatus.CodeNotFound ? (
                    <div>
                        <h1 className={'text-center'}>Code not found in group description</h1>
                        <p>Please make sure you have entered the code correctly and try again.</p>
                    </div>
                ) : (
                    <div className={'text-center'}>
                        <h1 className={'text-center'}>Code found in group description</h1>
                        <p>If you would like to continue with the linking process, press Link group.</p>
                        <p className={'fw-medium'}>
                            If your group is not free-to-join you will be required to invite or accept a join request
                            from the bot.
                        </p>
                        <div className={'buttons'}>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    groupsApi
                                        .linkGroupFinish(guildId, { groupId: groupDetails?.id ?? '' })
                                        .then((data) => {
                                            const status = data.data.status;
                                            setCurrentStatus(status ?? MemberStatus.Unknown);
                                            if (status === MemberStatus.Member) {
                                                onClose();
                                                onFinished();
                                            }
                                        });
                                }}
                            >
                                Link Group
                            </button>
                            <UrlButton
                                url={`https://vrchat.com/home/group/${groupDetails?.id}/invites`}
                                text={'Open Group Page'}
                                classes={'btn-primary'}
                            />
                        </div>
                        {currentStatus === MemberStatus.Requested ? (
                            <div>
                                <h3 className={'text-danger'}>
                                    Waiting for group join request to be accepted, after accepting press Link Group
                                    again.
                                </h3>
                            </div>
                        ) : currentStatus === MemberStatus.Invited ? (
                            <div>
                                <h3 className={'text-danger'}>You must invite the bot to your group</h3>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </PopupModal>
    );
};

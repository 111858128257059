import React from "react";
import {LimitedGuild} from "../api";

interface ServerItemProps {
    server: LimitedGuild;
    selectedServer: string|undefined;
    setSelectedServer: (id: string) => void;
}

const ServerItem: React.FC<ServerItemProps> = ({ server, selectedServer, setSelectedServer }) => {
  return (
      <div className={"list-group-item " + (selectedServer === server.id ? 'active ' : '')}
           onClick={() => {
               // Add logic to select the server
               setSelectedServer(server.id);
           }}
      >
          <img src={server.icon?.toString()} className="server-icon" alt=""/>
          <span>{server.name}</span>
      </div>
  );
};

export default ServerItem;
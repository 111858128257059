// src/pages/ServerSettings.tsx
import React, { useEffect } from 'react';
import './ServerSettings.css';
import DiscordSettings from '../components/DiscordSettings';
import ServerItem from '../components/ServerItem';
import { GuildsApi, LimitedGuild } from '../api';
import VRChatSettings from '../components/VRChatSettings';

const guildsApi = new GuildsApi();

enum ServerType {
    DISCORD = 'discord',
    VRCHAT = 'vrchat',
}

const ServerSettings: React.FC = () => {
    const [servers, setServers] = React.useState<LimitedGuild[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [serverType, setServerType] = React.useState<ServerType>(ServerType.DISCORD);
    const [selectedServer, setSelectedServerInternal] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        setIsLoading(true);
        guildsApi.getGuildList().then((response) => {
            setServers(response.data.guilds);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (selectedServer) {
            window.history.pushState({}, '', `/server-settings/${selectedServer}/${serverType}`);
        }
    }, [selectedServer, serverType]);

    useEffect(() => {
        const path = window.location.pathname.split('/');
        if (path.length > 3) {
            setSelectedServerInternal(path[2]);
            setServerType(path[3] as ServerType);
        }
    }, []);

    function setSelectedServer(serverId: string) {
        setSelectedServerInternal(serverId);
        setServerType(ServerType.DISCORD);
    }

    return (
        <div className="server-settings">
            <div className="sidebar">
                <h3>Select Your Server</h3>
                {isLoading && <div className="loading">Loading...</div>}
                {!isLoading && <p>Total Servers: {servers.length}</p>}
                <div className="buttons">
                    <button
                        className={`btn ${selectedServer === undefined ? 'btn-dark' : serverType === ServerType.DISCORD ? 'btn-primary' : 'btn-info'}`}
                        onClick={() => setServerType(ServerType.DISCORD)}
                    >
                        Discord Settings
                    </button>
                    <button
                        className={`btn ${selectedServer === undefined ? 'btn-dark' : serverType === ServerType.VRCHAT ? 'btn-primary' : 'btn-info'}`}
                        onClick={() => setServerType(ServerType.VRCHAT)}
                    >
                        VRChat Settings
                    </button>
                </div>

                <div className="list-group user-select-none server-list">
                    {servers.map((server) => (
                        <ServerItem
                            server={server}
                            selectedServer={selectedServer}
                            setSelectedServer={setSelectedServer}
                            key={server.id}
                        ></ServerItem>
                    ))}
                </div>
            </div>
            <div className="content">
                {selectedServer ? (
                    serverType === ServerType.DISCORD ? (
                        <DiscordSettings serverId={selectedServer} />
                    ) : (
                        <VRChatSettings serverId={selectedServer} />
                    )
                ) : (
                    <div className="no-server-selected">
                        <h1>No Server Selected</h1>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServerSettings;

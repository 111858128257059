// src/pages/Tos.tsx
import React from 'react';

const Tos: React.FC = () => {
  return (
      <div className="container mt-5">
          <h1>Terms of Service</h1>

          <h2>Last updated: May 18, 2024</h2>

          <h3>1. Acceptance of Terms</h3>

          <p>By accessing or using the VRCLinking bot service, you agree to be bound by these Terms of Service. If you
              do not agree to these Terms, you may not use the service.</p>

          <h3>2. Use of the Service</h3>

          <h4>2.1 Eligibility:</h4>
          <p>You must be at least 13 years old to use the VRCLinking bot service. World creators must be 16 years of age
              or older.</p>

          <h4>2.2 Account Creation:</h4>
          <p>In order to use certain features of the service, you may be required to create an account. You are
              responsible for maintaining the confidentiality of your account credentials and for all activities that
              occur under your account.</p>

          <h4>2.3 Prohibited Conduct:</h4>
          <p>You agree not to engage in any conduct that violates these Terms or any applicable laws or regulations.
              This includes, but is not limited to:</p>

          <li>Using the service for any unlawful purpose or in any way that may harm or interfere with the operation of
              the service or its users.
          </li>
          <li>Uploading or sharing any content that is infringing, obscene, defamatory, or violates the rights of any
              third party.
          </li>
          <li>Attempting to gain unauthorized access to the service or its related systems or networks.</li>
          <li>Using the service to blacklist users from worlds or engage in any unauthorized activity.</li>

          <h3>3. Intellectual Property</h3>

          <p>The VRCLinking bot service and its content, including but not limited to the software, logos, designs, and
              graphics, are the intellectual property of VRCLinking. You may not use, reproduce, modify, or distribute
              any part of the service without our prior written consent.</p>

          <h3>4. Modifications to the Service</h3>

          <p>We reserve the right to modify, suspend, or discontinue the VRCLinking bot service at any time without
              prior notice. We may also add or remove features or functionalities as we see fit.</p>

          <h3>5. Disclaimer of Warranty and Limitation of Liability</h3>

          <h4>5.1 Disclaimer of Warranty:</h4>
          <p>The VRCLinking bot service is provided on an "as is" basis without warranties of any kind, whether express
              or implied. We do not warrant that the service will be uninterrupted, error-free, or free from viruses or
              other harmful components.</p>

          <h4>5.2 Limitation of Liability:</h4>
          <p>In no event shall VRCLinking, its affiliates, or its directors, officers, employees, or agents be liable
              for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or
              related to your use of the VRCLinking bot service. This includes, but is not limited to, damages for loss
              of profits, goodwill, data, or other intangible losses.</p>

          <h3>6. Termination</h3>

          <p>We may terminate or suspend your access to the VRCLinking bot service without prior notice or liability,
              for any reason whatsoever, including without limitation if you breach these Terms of Service.

              <h3>7. Changes to the Terms of Service</h3>
              <p>We reserve the right to modify or replace these Terms of Service at any time. The most current version
                  will always be posted on our website. Your continued use of the service after any changes to the Terms
                  signifies your acceptance of those changes.</p>
              <h3>8. Severability</h3>
              <p>If any provision of these Terms is found to be invalid or unenforceable, that provision shall be
                  enforced to the maximum extent permitted by applicable law, and the other provisions of these Terms
                  shall remain in full force and effect.</p>
              <h3>9. Contact Us</h3>
              <p>If you have any questions or concerns regarding these Terms of Service, please contact us at
                  admin@miner28.com.</p>
          </p>
      </div>
);
};

export default Tos;

// components/ServerDetails.tsx
import React from 'react';
import './DiscordSettings.css';
import ToggleItem from './ToggleItem';
import { groupsApi } from '../api/helpers/groups-api';
import { GetGroupResponse } from '../api';
import { VRChatGroupLinking } from './VRChatGroupLinking';
import { UrlButton } from './UrlButton';
import { PopupModal } from './PopupModal';
import { GroupRolePicker } from './GroupRolePicker';
import serverSettings from '../pages/ServerSettings';

interface ServerDetailsProps {
    serverId: string;
}

const VRChatSettings: React.FC<ServerDetailsProps> = (server) => {
    const [groupDetails, setGroupDetails] = React.useState<GetGroupResponse>({
        id: '',
        name: 'Unknown',
        memberCount: 0,
        onlineMemberCount: 0,
        iconUrl: 'https://cdn.discordapp.com/embed/avatars/0.png',
        enabled: false,
        autoKick: false,
    });
    const [isLoading, setIsLoading] = React.useState(true);
    const [showUnlinkModal, setShowUnlinkModal] = React.useState(false);

    React.useEffect(
        () => {
            reloadGroupDetails();
        },
        // eslint-disable-next-line
        [server.serverId]
    );

    function reloadGroupDetails() {
        setIsLoading(true);
        groupsApi
            .getGroup(server.serverId)
            .then((response) => {
                setGroupDetails(response.data);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    setGroupDetails({
                        id: '',
                        name: 'Unknown',
                        memberCount: 0,
                        onlineMemberCount: 0,
                        iconUrl: 'https://cdn.discordapp.com/embed/avatars/0.png',
                        enabled: false,
                        autoKick: false,
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return isLoading ? (
        <div className="server-details"></div>
    ) : (
        <div className="server-details">
            <div className="icon">
                {groupDetails.iconUrl ? <img src={groupDetails.iconUrl?.toString()} alt="Server Icon" /> : null}
            </div>
            {groupDetails.id ? (
                <div>
                    <h1>{groupDetails.name}</h1>
                    <div className="member-info">
                        <div>
                            <p>Members: {groupDetails.memberCount}</p>
                        </div>
                        <div>
                            <p>Online Members: {groupDetails.onlineMemberCount}</p>
                        </div>
                    </div>
                    <div className="data-urls">
                        <UrlButton
                            url={'https://vrchat.com/home/group/' + groupDetails.id}
                            text={'Open Group Page'}
                            classes={'btn-primary'}
                        />
                        <button className={'btn btn-danger'} onClick={(event) => setShowUnlinkModal(true)}>
                            Unlink Group
                        </button>
                    </div>

                    <div className={'setting-title'}>
                        <h1>Settings</h1>
                    </div>

                    <div className="server-toggles list-group">
                        <ToggleItem
                            name={'Enable Groups feature'}
                            enabled={groupDetails.enabled ?? false}
                            onChange={(event) => {
                                groupsApi
                                    .updateGroup(server.serverId, { enabled: event.target.checked })
                                    .then(() => {});
                                groupDetails.enabled = event.target.checked;
                                setGroupDetails({ ...groupDetails });
                            }}
                        />
                        <ToggleItem
                            name={'Auto Remove Members without membership'}
                            enabled={groupDetails.autoKick ?? false}
                            onChange={(event) => {
                                groupsApi
                                    .updateGroup(server.serverId, { autoKick: event.target.checked })
                                    .then(() => {});
                                groupDetails.autoKick = event.target.checked;
                                setGroupDetails({ ...groupDetails });
                            }}
                        />
                        <GroupRolePicker guildId={server.serverId} />
                    </div>
                </div>
            ) : (
                <VRChatGroupLinking guildId={server.serverId} onFinished={reloadGroupDetails} />
            )}
            <PopupModal
                show={showUnlinkModal}
                onClose={() => setShowUnlinkModal(false)}
                title={'Unlink Group Confirmation'}
            >
                <div>
                    <p>Are you sure you want to unlink this group?</p>
                    <div>
                        <button
                            className={'btn btn-primary'}
                            onClick={(event) => {
                                groupsApi.unlinkGroup(server.serverId).then(() => {
                                    setShowUnlinkModal(false);
                                    reloadGroupDetails();
                                });
                            }}
                        >
                            Unlink
                        </button>

                        <button className={'btn btn-danger'} onClick={(event) => setShowUnlinkModal(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </PopupModal>
        </div>
    );
};

export default VRChatSettings;

import React from 'react';
import { AuthApi, Configuration } from '../api';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_PATH } from '../api/base';

const axiosInstance = axios.create({
    withCredentials: true,
});

const apiConfig = new Configuration({
    basePath: BASE_PATH,
    baseOptions: {
        withCredentials: true,
    },
});

const authApi = new AuthApi(apiConfig, undefined, axiosInstance);

const LoginCallback: React.FC = () => {
    const location = useLocation();
    const loginCallback = async () => {
        const code = new URLSearchParams(location.search).get('code');
        if (code) {
            await handleAuthCode(code);
        }
    };

    const handleAuthCode = async (code: string) => {
        try {
            await authApi.loginCallback({ code: code });
            window.location.href = '/';
        } catch (e) {
            console.error(e);
            window.location.href = '/';
        }
    };

    React.useEffect(() => {
        loginCallback();
    });

    return <div>Logging in... Please wait.</div>;
};

export default LoginCallback;

// src/pages/PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
      <div className="container mt-5">
          <h1>Privacy Policy</h1>

          <h2>Last updated: May 18, 2024</h2>

          <h3>1. Information We Collect</h3>

          <h4>1.1. Discord Account Linking:</h4>
          <p>When you use the VRCLinking bot, we collect and store the following information from your Discord and
              VRChat
              accounts:</p>

          <li>VRChat ID</li>
          <li>VRChat display name</li>
          <li>Discord ID</li>
          <li>Discord username and displayname</li>
          <li>Discord server information (roles)</li>


          <h4>1.2. Discord Server Information:</h4>
          <p>As part of the bot functionality, we collect and store information about Discord servers the bot is present
              in, including the IDs of all server members. This information is used to enable
              server-specific
              features and populate a JSON file that world creators can utilize.</p>

          <h4>1.3. Website Login for World Creators:</h4>
          <p>If you log in to our website as a world creator, we collect and store the following information:</p>
          <li>Display name</li>
          <li>Discord ID</li>
          <li>Avatar</li>
          <li>Authentication token (used to fetch the above information from Discord)</li>

          <h3>2. Use of Information</h3>

          <p>We use the collected information to provide and maintain the VRCLinking bot service, including:</p>
          <li>Linking your Discord account to your VRChat account</li>
          <li>Enabling bot functionality within Discord servers</li>
          <li>Allowing world creators to access and edit their server's configuration on the website</li>

          <p>We utilize Discord roles to manage website access, granting appropriate permissions to world creators based
              on their roles.</p>

          <h3>3. Data Retention and Deletion</h3>

          <p>We retain most personal information for a maximum of 72 hours. After 72 hours or when a user logs out,
              whichever occurs first,
              the data is automatically deleted from our servers.</p>
          <p>
              We retain the following information for indefinite periods of time:
              <li>VRChat ID</li>
              <li>Discord ID</li>
              <li>Discord username and displayname</li>
              <li>Discord server information (members, roles)</li>
          </p>

          <h3>4. User Rights</h3>

          <p>You have the right to access and correct your personal information. Regular users may run the "/link"
              command
              on Discord to access and correct their data. World creators can make edits on the website. For any data
              deletion requests or inquiries, please contact us at admin@miner28.com.</p>

          <h3>5. Publicly Accessible Display Names</h3>

          <p>We generate a JSON file containing display names collected from VRChat accounts. Please note that this JSON
              file is publicly accessible to everyone and only includes display names. The purpose of sharing display
              names publicly is to enable users listed in the JSON file to gain access to special features or items
              provided by the world creators, such as supporter items for users who are supporters of the world
              creator.</p>

          <h3>6. Security</h3>

          <p>We implement reasonable security measures to protect personal information from unauthorized access,
              disclosure, or destruction. All data is stored on secure servers, and personal identification data is
              hashed
              to ensure privacy. Data transfer isencrypted and runs over HTTPS for secure communication.

              <h3>7. Cookies and Session Management</h3>
              <p>Our website and bot use cookies to maintain session IDs, allowing users to stay logged in and prevent
                  frequent logins. You can manage your cookie preferences through your browser settings.</p>
              <h3>8. Children's Privacy</h3>
              <p>The VRCLinking bot and website are intended for users aged 13 and above. However, world creators must
                  be 16
                  years of age or older to use the service. We do not knowingly collect personal information from
                  individuals
                  below the minimum age requirement.</p>
              <h3>9. Contact Us</h3>
              <p>If you have any questions, concerns, or requests regarding your personal information or this Privacy
                  Policy,
                  please contact us at admin@miner28.com.</p>
          </p>
      </div>
);
};

export default PrivacyPolicy;

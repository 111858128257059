// src/pages/Index.tsx
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tailwindcss/tailwind.css';
import './MainContent.css';

const Index: React.FC = () => {
  return (
    <main className="index-main">
      <div className="container text-center mt-5">
        <h1>VRChat Linking Bot</h1>
        <h2>Made by @miner28_3</h2>
        <p>Discord Bot for World Creators to allow their users to link their VRChat account to their discord account.<br />
          Includes automatic list updating, and a dashboard for world creators to manage their server and encoding options.<br />
          Custom Template support for world creators to customize the styling.<br />
          Guest User support for world creators that wish to allow their users to give their friends access.<br />
          Automatic Group Invites for discord members that link their VRChat account.
        </p>
        <a href="https://discord.gg/KJQzwDnsf3" className="btn btn-info mr-2" role="button">Support Discord</a>
        <a href="https://discord.com/api/oauth2/authorize?client_id=938573401201721425&permissions=2147600448&scope=bot%20applications.commands" className="btn btn-info" role="button">Invite to Server</a>
      </div>
    </main>
  );
};

export default Index;

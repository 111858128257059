import { PopupModal } from './PopupModal';
import React from 'react';
import './ButtonWithConfirmation.css';

export const ButtonWithConfirmation = (props: {
    name: string;
    confirmTitle: string;
    onConfirm?: () => void;
    className?: string;
    style?: React.CSSProperties;
}) => {
    const [show, setShow] = React.useState(false);
    return (
        <div>
            <button
                className={'btn ' + props.className}
                color="primary"
                onClick={() => setShow(true)}
                style={props.style}
            >
                {props.name}
            </button>
            <PopupModal
                show={show}
                onClose={() => {
                    setShow(false);
                }}
                title={props.confirmTitle}
            >
                <div className={'button-confirmation-container'}>
                    <button
                        className={'btn btn-success'}
                        onClick={() => {
                            props.onConfirm?.();
                            setShow(false);
                        }}
                    >
                        Confirm
                    </button>
                    <button
                        className={'btn btn-danger'}
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </PopupModal>
        </div>
    );
};

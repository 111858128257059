import React from "react";
import './InputFieldItem.css';

interface InputFieldItemProps {
    name: string;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
}

const InputFieldItem: React.FC<InputFieldItemProps> = ({name, placeholder, value, onChange}) => {

    return (
        <div className="list-group-item server-toggles-item">
        <h3>{name}</h3>
        <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            value={value}
            onChange={event => {
                onChange(event.target.value);
            }}
        />
        </div>
    );
}

export default InputFieldItem;
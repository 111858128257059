import React, { MouseEventHandler } from 'react';
import Select, { components, MultiValueGenericProps, MultiValueProps, OnChangeValue, StylesConfig } from 'react-select';
import { Modal } from 'react-bootstrap';
import ButtonItem from './ButtonItem';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import './SelectPickerItem.css';

interface SelectPickerItemProps {
    name: string;
    modalTitle: string;
    buttonName: string;
    options: SelectOption[];
    selectedOptions: SelectOption[];
    onChange: (selectedOptions: OnChangeValue<SelectOption, true>) => void;
}

interface SelectOption {
    value: string;
    label: string;
    color: string;
    position: number;
}

// Custom Sortable MultiValue component using @dnd-kit
const SortableMultiValue = (props: MultiValueProps<SelectOption>) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.data.value,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} onMouseDown={onMouseDown}>
            <components.MultiValue {...props} />
        </div>
    );
};

const SortableMultiValueLabel = (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />;

const colourStyles: StylesConfig<SelectOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: '#4c4c4c', borderColor: '#4c4c4c' }),
    option: (styles, { data }) => ({
        ...styles,
        backgroundColor: data.color,
        color: '#FFFFFF',
    }),
    multiValue: (styles, { data }) => ({
        ...styles,
        backgroundColor: data.color,
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#FFFFFF',
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: '#FF0000',
    }),
};

const SelectPickerItem: React.FC<SelectPickerItemProps> = ({
    name,
    modalTitle,
    buttonName,
    options,
    selectedOptions,
    onChange,
}) => {
    const [show, setShow] = React.useState(false);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 4,
            },
        })
    );

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = selectedOptions.findIndex((item) => item.value === active.id);
            const newIndex = selectedOptions.findIndex((item) => item.value === over.id);
            const newValue = arrayMove(selectedOptions, oldIndex, newIndex);
            onChange(newValue);
        }
    };

    const handleClose = () => setShow(false);

    return (
        <div style={{ borderRadius: 'inherit' }}>
            <ButtonItem name={name} buttonName={buttonName} onClick={() => setShow(true)} />
            <div className={'modal show'} style={{ display: 'block', position: 'initial' }}>
                <Modal show={show} onHide={handleClose} contentClassName={'bg-dark'}>
                    <Modal.Header>
                        <div className={'modal-header-div'}>
                            <h1 className={'modal-header-title'}>{modalTitle}</h1>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="server-toggles-item">
                            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                <SortableContext
                                    items={selectedOptions.map((item) => item.value)}
                                    strategy={rectSortingStrategy}
                                >
                                    <Select
                                        isMulti
                                        options={options}
                                        value={selectedOptions}
                                        onChange={onChange}
                                        components={{
                                            // @ts-ignore
                                            MultiValue: SortableMultiValue,
                                            // @ts-ignore
                                            MultiValueLabel: SortableMultiValueLabel,
                                        }}
                                        closeMenuOnSelect={false}
                                        className={'server-toggles-item-select'}
                                        styles={colourStyles}
                                    />
                                </SortableContext>
                            </DndContext>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default SelectPickerItem;

import { CustomButton } from './CustomButton';
import React, { useEffect } from 'react';
import { ProfileResponse, Status, VRChatUser } from '../api';
import { useAuth } from '../pages/AuthProvider';
import { PopupModal } from './PopupModal';
import { useDebounce } from 'use-debounce';
import './AccountLinking.css';
import { usersApi } from '../api/helpers/users-api';
import { ButtonWithConfirmation } from './ButtonWithConfirmation';

interface AccountLinkingProps {
    profileData: ProfileResponse;
    refresh: () => void;
}
export const AccountLinking = (props: AccountLinkingProps) => {
    const { userData } = useAuth();
    const [isLinkingModalOpen, setIsLinkingModalOpen] = React.useState(false);
    const [vrChatInput, setVrChatInput] = React.useState('');
    const [debouncedVrChatInput] = useDebounce(vrChatInput, 500);
    const [searchResults, setSearchResults] = React.useState<VRChatUser[]>([]);
    const [linkPhase, setLinkPhase] = React.useState(0);
    const [selectedUser, setSelectedUser] = React.useState<VRChatUser | null>(null);
    const [verificationCode, setVerificationCode] = React.useState('');
    const [codeFound, setCodeFound] = React.useState(true);

    useEffect(() => {
        if (!debouncedVrChatInput) return;
        usersApi.searchUsers(debouncedVrChatInput).then((response) => {
            setSearchResults(response.data);
        });
    }, [debouncedVrChatInput]);

    return (
        <div>
            <div className={'link-account-buttons'}>
                <CustomButton
                    name={'Link Account'}
                    className={'btn-success'}
                    onClick={() => {
                        setIsLinkingModalOpen(true);
                        setLinkPhase(0);
                        setVrChatInput('');
                        setSearchResults([]);
                        setSelectedUser(null);
                    }}
                />
                {props.profileData.linkData.id && (
                    <ButtonWithConfirmation
                        name={'Refresh Link'}
                        confirmTitle={'Are you sure you want to refresh the link?'}
                        className={'btn-primary'}
                        onConfirm={() => {
                            usersApi
                                .refreshLink()
                                .then(() => {
                                    props.refresh();
                                })
                                .catch((error) => {
                                    console.error(error);
                                    alert('An error occurred while refreshing the link.');
                                });
                        }}
                    />
                )}
                {props.profileData.linkData.id && (
                    <ButtonWithConfirmation
                        name={'Unlink Account'}
                        confirmTitle={'Are you sure you want to unlink the account?'}
                        className={'btn-danger'}
                        onConfirm={() => {
                            usersApi
                                .unlinkUser()
                                .then(() => {
                                    props.refresh();
                                })
                                .catch((error) => {
                                    console.error(error);
                                    alert('An error occurred while unlinking the account.');
                                });
                        }}
                    />
                )}
                {props.profileData.linkData.id && (
                    <ButtonWithConfirmation
                        name={'Reset Group Invites'}
                        confirmTitle={'Are you sure you want to reset group invites?'}
                        className={'btn-secondary'}
                        onConfirm={() => {
                            usersApi
                                .resetGroupInvites()
                                .then(() => {
                                    props.refresh();
                                })
                                .catch((error) => {
                                    console.error(error);
                                    alert('An error occurred while resetting group invites.');
                                });
                        }}
                    />
                )}
            </div>
            <PopupModal show={isLinkingModalOpen} onClose={() => setIsLinkingModalOpen(false)} title={'Link Account'}>
                <div className={'linking-container'}>
                    {linkPhase === 0 && (
                        <input
                            type="text"
                            placeholder="Enter your VRChat Username, ID or profile URL"
                            value={vrChatInput}
                            onChange={(e) => setVrChatInput(e.target.value)}
                            className={'input-group-text user-link-input'}
                        />
                    )}
                    {linkPhase === 0 && searchResults.length > 0 && (
                        <div className={'search-results'}>
                            {searchResults.map((user) => (
                                <div key={user.id} className={'search-result'}>
                                    <img src={user.profileUrl} alt={'User Avatar'} className={'search-result-avatar'} />
                                    <div>
                                        <div className={'search-result-username'}>{user.displayName}</div>
                                        <div className={'search-result-id'}>{user.id}</div>
                                    </div>
                                    <CustomButton
                                        name={'Link'}
                                        className={'btn-primary'}
                                        onClick={() => {
                                            usersApi.checkUser(user.id).then((response) => {
                                                setSelectedUser(user);
                                                setVerificationCode(response.data.verificationCode);
                                                if (response.data.status === Status.Conflict) {
                                                    setLinkPhase(101);
                                                    setCodeFound(true);
                                                    return;
                                                }
                                                setLinkPhase(1);
                                            });
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {linkPhase === 1 && (
                        <div className={'search-results'}>
                            <div className={'search-result'}>
                                <img
                                    src={selectedUser?.profileUrl}
                                    alt={'User Avatar'}
                                    className={'search-result-avatar'}
                                />
                                <div>
                                    <div className={'search-result-username'}>{selectedUser?.displayName}</div>
                                    <div className={'search-result-id'}>{selectedUser?.id}</div>
                                </div>
                            </div>
                            <div className={'search-confirmation'}>
                                <CustomButton
                                    name={'Back'}
                                    className={'btn-secondary'}
                                    onClick={() => setLinkPhase(0)}
                                />
                                <CustomButton
                                    name={'Confirm'}
                                    className={'btn-success'}
                                    onClick={() => {
                                        usersApi.linkUser(selectedUser!.id).then((response) => {
                                            if (response.data.status === Status.SuccessVerified) {
                                                setLinkPhase(2);
                                                props.refresh();
                                            } else if (response.data.status === Status.Success) {
                                                setLinkPhase(102);
                                                props.refresh();
                                            }
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {linkPhase === 2 && (
                        <div className={'linking-success'}>
                            <h2>Account Verified</h2>
                            <p>Your VRChat account has been successfully linked and verified to your account.</p>
                            <CustomButton
                                name={'Close'}
                                className={'btn-primary'}
                                onClick={() => {
                                    setIsLinkingModalOpen(false);
                                    props.refresh();
                                }}
                            />
                        </div>
                    )}
                    {linkPhase === 101 && (
                        <div className={'linking-conflict'}>
                            <h2>Conflict Detected</h2>
                            <p>
                                The VRChat account you are trying to link is already linked to another account. If this
                                is your account, you must unlink it from the other account before you can link it here.
                                If you don't have access to the other account, you must go through Account Verification
                                to prove that you own the VRChat account. Please enter the verification code below into
                                your bio and click Verify Account.
                            </p>
                            <div className={'linking-code'}>
                                <p>{verificationCode}</p>{' '}
                                {!codeFound && (
                                    <p className={'linking-code-not-found'}>Code not found, try again later</p>
                                )}
                            </div>
                            <div className={'linking-conflict-buttons'}>
                                <CustomButton
                                    name={'Back'}
                                    className={'btn-secondary'}
                                    onClick={() => setLinkPhase(0)}
                                />
                                <CustomButton
                                    name={'Copy'}
                                    className={'btn-primary'}
                                    onClick={() => navigator.clipboard.writeText(verificationCode)}
                                />
                                <CustomButton
                                    name={'Verify Account'}
                                    className={'btn-success'}
                                    onClick={() => {
                                        usersApi
                                            .verifyUser(selectedUser!.id)
                                            .then(() => {
                                                setLinkPhase(1);
                                                setCodeFound(true);
                                            })
                                            .catch((error) => {
                                                setCodeFound(false);
                                            });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {linkPhase === 102 && (
                        <div className={'linking-conflict'}>
                            <h2>Account Linked</h2>
                            <p>
                                Your VRChat account has been successfully linked to your account. You may now verify
                                your account by entering the verification code below into your bio. If you have already
                                done this, please click the Verify Account button. You may need to verify your account
                                for some features to work. Check with the Discord server you're wanting to use for more
                                information.
                            </p>
                            <div className={'linking-code'}>
                                <p>{verificationCode}</p>{' '}
                                {!codeFound && (
                                    <p className={'linking-code-not-found'}>Code not found, try again later</p>
                                )}
                            </div>
                            <div className={'linking-conflict-buttons'}>
                                <CustomButton
                                    name={'Back'}
                                    className={'btn-secondary'}
                                    onClick={() => setLinkPhase(0)}
                                />
                                <CustomButton
                                    name={'Copy'}
                                    className={'btn-primary'}
                                    onClick={() => navigator.clipboard.writeText(verificationCode)}
                                />
                                <CustomButton
                                    name={'Verify Account'}
                                    className={'btn-success'}
                                    onClick={() => {
                                        usersApi
                                            .verifyUser(selectedUser!.id)
                                            .then(() => {
                                                setLinkPhase(1);
                                                setCodeFound(true);
                                            })
                                            .catch((error) => {
                                                setCodeFound(false);
                                            });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </PopupModal>
        </div>
    );
};

import { GuildsApi } from '../api';

export const guildsApi = new GuildsApi();

export const updateGuild = async (
    serverId: string,
    grp_enabled: boolean | null = null,
    always_enc_roles: Array<string> | null = null,
    enc_role_list: Array<string> | null = null
) => {
    return guildsApi.updateGuild(serverId, {
        grpEnabled: grp_enabled,
        alwaysEncRoles: always_enc_roles,
        encRoleList: enc_role_list,
    });
};

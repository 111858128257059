import React, { useEffect, useState } from 'react';
import ButtonItem from './ButtonItem';
import { PopupModal } from './PopupModal';
import './GuestUsersPicker.css';
import { CustomButton } from './CustomButton';
import { guildsApi } from '../api/helpers/guilds-api';
import { GuestRole, Guild } from '../api';
import { darkThemeSelect } from './Styles';
import Select, { ActionMeta, OnChangeValue } from 'react-select';

export const GuestUsersPicker = (props: { guild: Guild }) => {
    const [show, setShow] = useState(false);
    const [guestRoles, setGuestRoles] = useState<GuestRole[]>([]);

    useEffect(() => {
        if (!show) {
            return;
        }

        guildsApi.getGuestRoles(props.guild.id).then((response) => {
            setGuestRoles(response.data.guestRoles);
        });
    }, [show, props.guild.id]);

    function updateGuestRole(id: string, count: number, mainRoleId?: string, guestRoleId?: string) {
        setGuestRoles((prevGuestRoles) => {
            return prevGuestRoles.map((guestRole) => {
                if (guestRole.id === id) {
                    return {
                        ...guestRole,
                        primaryRoleId: mainRoleId === '' ? null : mainRoleId,
                        guestRoleId: guestRoleId === '' ? null : guestRoleId,
                        count: count,
                    };
                }
                return guestRole;
            });
        });

        guildsApi
            .updateGuestRole(props.guild.id, {
                primaryRoleId: mainRoleId,
                guestRoleId: guestRoleId,
                count: count,
                id: id,
            })
            .then(() => {
                // Success
            });
    }

    const GuestUsersRow = ({ count, guestRoleId, index, mainRoleId, id, remove }: GuestUsersRowProps) => {
        const options: OptionType[] = props.guild.roles.map((role) => {
            return {
                value: role.id ?? '',
                label: role.name ?? '',
            };
        });

        const countOptions = Array.from({ length: 26 }, (_, i) => i);
        const countOptionsMapped = countOptions.map((count) => {
            return {
                value: count.toString(),
                label: count.toString(),
            };
        });

        const handleMainRoleChange = (value: OnChangeValue<OptionType, false>, meta: ActionMeta<OptionType>) => {
            // Update the main role
            updateGuestRole(id, count, value?.value, guestRoleId ?? undefined);
        };

        const handleGuestRoleChange = (value: OnChangeValue<OptionType, false>, meta: ActionMeta<OptionType>) => {
            // Update the guest role
            updateGuestRole(id, count, mainRoleId ?? undefined, value?.value);
        };

        const handleCountChange = (value: OnChangeValue<OptionType, false>, meta: ActionMeta<OptionType>) => {
            // Update the count
            const count = parseInt(value?.value ?? '0');
            updateGuestRole(id, count, mainRoleId ?? undefined, guestRoleId ?? undefined);
        };

        return (
            <tr className="guest-users-row">
                <td>{index}</td>
                <td>
                    <Select<OptionType>
                        className={'guest-users-select'}
                        value={options.find((option) => option.value === mainRoleId)}
                        options={options}
                        styles={darkThemeSelect}
                        onChange={handleMainRoleChange}
                        isMulti={false}
                    />
                </td>
                <td>
                    <Select
                        className={'guest-users-select'}
                        value={options.find((option) => option.value === guestRoleId)}
                        options={options}
                        styles={darkThemeSelect}
                        isMulti={false}
                        onChange={handleGuestRoleChange!}
                    />
                </td>

                <td>
                    <Select
                        className={'guest-users-select'}
                        value={countOptionsMapped.find((option) => option.value === count.toString())}
                        options={countOptionsMapped}
                        styles={darkThemeSelect}
                        isMulti={false}
                        onChange={handleCountChange!}
                    />
                </td>
                <td>
                    <CustomButton name={'Remove'} className={'btn-danger'} onClick={remove} />
                </td>
            </tr>
        );
    };

    return (
        <div>
            <ButtonItem name={'Guest Roles'} buttonName={'Setup'} onClick={() => setShow(true)} />
            <PopupModal show={show} onClose={() => setShow(false)} title={'Guest Users'}>
                <table className="guest-users-picker table table-dark table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Main Role</th>
                            <th>Guest Role</th>
                            <th>Count</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {guestRoles.map((guestRole, index) => (
                            <GuestUsersRow
                                key={index}
                                index={index + 1}
                                id={guestRole.id}
                                mainRoleId={guestRole.primaryRoleId}
                                guestRoleId={guestRole.guestRoleId}
                                count={guestRole.count ?? 0}
                                remove={() => {
                                    guildsApi
                                        .deleteGuestRole(props.guild.id, guestRole.id)
                                        .then(() => {
                                            setGuestRoles((prevGuestRoles) => {
                                                return prevGuestRoles.filter((role) => role.id !== guestRole.id);
                                            });
                                        })
                                        .catch((error) => {
                                            if (error.response.status !== 404 && error.response.status !== 400) {
                                                console.error(error);
                                                alert('An error occurred while deleting the guest role.');
                                            }
                                        });
                                }}
                            />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>
                                <CustomButton
                                    name={'Add'}
                                    className={'btn-primary guest-users-add-button'}
                                    onClick={() => {
                                        guildsApi.createGuestRole(props.guild.id).then((response) => {
                                            setGuestRoles(response.data.guestRoles);
                                        });
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </PopupModal>
        </div>
    );
};

interface GuestUsersRowProps {
    index: number;
    id: string;
    mainRoleId?: string | null;
    guestRoleId?: string | null;
    count: number;
    remove?: () => void;
}

export interface OptionType {
    value: string;
    label: string;
}

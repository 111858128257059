import React from "react";
import './ButtonItem.css';

interface ButtonItemProps {
    name: string;
    buttonName: string;
    onClick: () => void;
}

const ButtonItem: React.FC<ButtonItemProps> = ({name, buttonName, onClick}) => {

    return (
        <div className="list-group-item server-toggles-item">
        <h3>{name}</h3>
            <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                    onClick();
                }}>{buttonName}</button>

        </div>
    );
}

export default ButtonItem;
import React from "react";
import Form from "react-bootstrap/Form";

interface SettingItemProps {
    name: string;
    enabled: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToggleItem: React.FC<SettingItemProps> = ({name, enabled, onChange}) => {
  return (
      <div className="list-group-item server-toggles-item">
          <h3>{name}</h3>
          <Form className="large-toggle">
              <Form.Check
                  type="switch"
                  id="custom-switch"
                  onChange={event => {
                        onChange(event);
                  }}
                    checked={enabled}

              />
          </Form>
      </div>
  );
};

export default ToggleItem;
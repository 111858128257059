/* tslint:disable */
/* eslint-disable */
/**
 * VRCLinking
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface CheckLinkGroupResponse
 */
export interface CheckLinkGroupResponse {
    /**
     * 
     * @type {LinkStatus}
     * @memberof CheckLinkGroupResponse
     */
    'status': LinkStatus;
}


/**
 * 
 * @export
 * @interface EncodeRole
 */
export interface EncodeRole {
    /**
     * 
     * @type {string}
     * @memberof EncodeRole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EncodeRole
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof EncodeRole
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof EncodeRole
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface FinishLinkGroupResponse
 */
export interface FinishLinkGroupResponse {
    /**
     * 
     * @type {MemberStatus}
     * @memberof FinishLinkGroupResponse
     */
    'status': MemberStatus;
}


/**
 * 
 * @export
 * @interface GetGroupResponse
 */
export interface GetGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof GetGroupResponse
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGroupResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGroupResponse
     */
    'shortCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGroupResponse
     */
    'discriminator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGroupResponse
     */
    'iconUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGroupResponse
     */
    'bannerUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGroupResponse
     */
    'ownerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGroupResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetGroupResponse
     */
    'memberCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetGroupResponse
     */
    'onlineMemberCount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetGroupResponse
     */
    'fullySynced'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetGroupResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetGroupResponse
     */
    'autoKick': boolean;
}
/**
 * 
 * @export
 * @interface GetGroupRolesResponse
 */
export interface GetGroupRolesResponse {
    /**
     * 
     * @type {GroupLinkedRole}
     * @memberof GetGroupRolesResponse
     */
    'everyoneRole': GroupLinkedRole;
    /**
     * 
     * @type {Array<Role>}
     * @memberof GetGroupRolesResponse
     */
    'guildRoles': Array<Role>;
    /**
     * 
     * @type {Array<GroupRole>}
     * @memberof GetGroupRolesResponse
     */
    'groupRoles': Array<GroupRole>;
    /**
     * 
     * @type {Array<GroupLinkedRole>}
     * @memberof GetGroupRolesResponse
     */
    'linkedGroupRoles': Array<GroupLinkedRole>;
}
/**
 * 
 * @export
 * @interface GetGuestRolesResponse
 */
export interface GetGuestRolesResponse {
    /**
     * 
     * @type {Array<GuestRole>}
     * @memberof GetGuestRolesResponse
     */
    'guestRoles': Array<GuestRole>;
}
/**
 * 
 * @export
 * @interface GetGuildsListResponse
 */
export interface GetGuildsListResponse {
    /**
     * 
     * @type {Array<LimitedGuild>}
     * @memberof GetGuildsListResponse
     */
    'guilds': Array<LimitedGuild>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GroupAction = {
    None: 'None',
    Membership: 'Membership',
    NoMembership: 'NoMembership',
    GrantRolePermanent: 'GrantRolePermanent',
    GrantRoleTemporary: 'GrantRoleTemporary',
    RevokeRole: 'RevokeRole'
} as const;

export type GroupAction = typeof GroupAction[keyof typeof GroupAction];


/**
 * 
 * @export
 * @interface GroupLinkedRole
 */
export interface GroupLinkedRole {
    /**
     * 
     * @type {string}
     * @memberof GroupLinkedRole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GroupLinkedRole
     */
    'discordRoleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupLinkedRole
     */
    'groupRoleId'?: string | null;
    /**
     * 
     * @type {GroupAction}
     * @memberof GroupLinkedRole
     */
    'action': GroupAction;
}


/**
 * 
 * @export
 * @interface GroupRole
 */
export interface GroupRole {
    /**
     * 
     * @type {string}
     * @memberof GroupRole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GroupRole
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GroupRole
     */
    'order'?: string | null;
}
/**
 * 
 * @export
 * @interface GuestRole
 */
export interface GuestRole {
    /**
     * 
     * @type {string}
     * @memberof GuestRole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GuestRole
     */
    'primaryRoleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuestRole
     */
    'primaryRoleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuestRole
     */
    'guestRoleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuestRole
     */
    'guestRoleName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GuestRole
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface Guild
 */
export interface Guild {
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    'owner': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Guild
     */
    'guestEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Guild
     */
    'grpEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    'grpId'?: string | null;
    /**
     * 
     * @type {Array<EncodeRole>}
     * @memberof Guild
     */
    'encRoleList': Array<EncodeRole>;
    /**
     * 
     * @type {Array<EncodeRole>}
     * @memberof Guild
     */
    'alwaysEncRoles': Array<EncodeRole>;
    /**
     * 
     * @type {number}
     * @memberof Guild
     */
    'members': number;
    /**
     * 
     * @type {number}
     * @memberof Guild
     */
    'linkedMembers': number;
    /**
     * 
     * @type {Array<Role>}
     * @memberof Guild
     */
    'roles': Array<Role>;
}
/**
 * 
 * @export
 * @interface LimitedGroup
 */
export interface LimitedGroup {
    /**
     * 
     * @type {string}
     * @memberof LimitedGroup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LimitedGroup
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LimitedGroup
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface LimitedGuild
 */
export interface LimitedGuild {
    /**
     * 
     * @type {string}
     * @memberof LimitedGuild
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LimitedGuild
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LimitedGuild
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface LinkData
 */
export interface LinkData {
    /**
     * 
     * @type {string}
     * @memberof LinkData
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LinkData
     */
    'vrchatName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LinkData
     */
    'isVerified'?: boolean | null;
}
/**
 * 
 * @export
 * @interface LinkGroupRequest
 */
export interface LinkGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkGroupRequest
     */
    'groupId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LinkStatus = {
    CodeFound: 'CodeFound',
    CodeNotFound: 'CodeNotFound'
} as const;

export type LinkStatus = typeof LinkStatus[keyof typeof LinkStatus];


/**
 * 
 * @export
 * @interface LinkingResponse
 */
export interface LinkingResponse {
    /**
     * 
     * @type {string}
     * @memberof LinkingResponse
     */
    'verificationCode': string;
    /**
     * 
     * @type {Status}
     * @memberof LinkingResponse
     */
    'status': Status;
}


/**
 * 
 * @export
 * @interface LoginCallbackRequest
 */
export interface LoginCallbackRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginCallbackRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface LoginCallbackResponse
 */
export interface LoginCallbackResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginCallbackResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginCallbackResponse
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginCallbackResponse
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginCallbackResponse
     */
    'globalName'?: string | null;
    /**
     * 
     * @type {UserRank}
     * @memberof LoginCallbackResponse
     */
    'rank': UserRank;
}


/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'redirectUri': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MemberStatus = {
    Member: 'Member',
    Invited: 'Invited',
    Requested: 'Requested',
    Unknown: 'Unknown'
} as const;

export type MemberStatus = typeof MemberStatus[keyof typeof MemberStatus];


/**
 * 
 * @export
 * @interface NotFoundResponse
 */
export interface NotFoundResponse {
    /**
     * 
     * @type {number}
     * @memberof NotFoundResponse
     */
    'statusCode': number;
    /**
     * 
     * @type {string}
     * @memberof NotFoundResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProfileResponse
 */
export interface ProfileResponse {
    /**
     * 
     * @type {User}
     * @memberof ProfileResponse
     */
    'user': User;
    /**
     * 
     * @type {LinkData}
     * @memberof ProfileResponse
     */
    'linkData': LinkData;
    /**
     * 
     * @type {Array<LimitedGroup>}
     * @memberof ProfileResponse
     */
    'groups': Array<LimitedGroup>;
    /**
     * 
     * @type {Array<LimitedGuild>}
     * @memberof ProfileResponse
     */
    'guilds': Array<LimitedGuild>;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'permissions': string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface StartLinkGroupResponse
 */
export interface StartLinkGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof StartLinkGroupResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StartLinkGroupResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StartLinkGroupResponse
     */
    'shortCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartLinkGroupResponse
     */
    'iconUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartLinkGroupResponse
     */
    'bannerUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartLinkGroupResponse
     */
    'ownerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartLinkGroupResponse
     */
    'linkCode': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Status = {
    Success: 'Success',
    Conflict: 'Conflict',
    SuccessVerified: 'SuccessVerified'
} as const;

export type Status = typeof Status[keyof typeof Status];


/**
 * 
 * @export
 * @interface UpdateGroupRequest
 */
export interface UpdateGroupRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGroupRequest
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGroupRequest
     */
    'autoKick'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateGuestRole
 */
export interface UpdateGuestRole {
    /**
     * 
     * @type {string}
     * @memberof UpdateGuestRole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGuestRole
     */
    'primaryRoleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGuestRole
     */
    'guestRoleId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGuestRole
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface UpdateGuildRequest
 */
export interface UpdateGuildRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGuildRequest
     */
    'grpEnabled'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateGuildRequest
     */
    'encRoleList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateGuildRequest
     */
    'alwaysEncRoles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'globalName'?: string | null;
    /**
     * 
     * @type {UserRank}
     * @memberof User
     */
    'rank': UserRank;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserRank = {
    User: 'User',
    Admin: 'Admin'
} as const;

export type UserRank = typeof UserRank[keyof typeof UserRank];


/**
 * 
 * @export
 * @interface VRChatUser
 */
export interface VRChatUser {
    /**
     * 
     * @type {string}
     * @memberof VRChatUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VRChatUser
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof VRChatUser
     */
    'profileUrl': string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginCallbackRequest} [loginCallbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginCallback: async (loginCallbackRequest?: LoginCallbackRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCallbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LoginCallbackRequest} [loginCallbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginCallback(loginCallbackRequest?: LoginCallbackRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginCallbackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginCallback(loginCallbackRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.loginCallback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginCallbackRequest} [loginCallbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginCallback(loginCallbackRequest?: LoginCallbackRequest, options?: any): AxiosPromise<LoginCallbackResponse> {
            return localVarFp.loginCallback(loginCallbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<object> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginCallbackRequest} [loginCallbackRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginCallback(loginCallbackRequest?: LoginCallbackRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginCallback(loginCallbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupUsersApi - axios parameter creator
 * @export
 */
export const GroupUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupRole: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('createGroupRole', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}/group_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupRole: async (guildId: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('deleteGroupRole', 'guildId', guildId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteGroupRole', 'roleId', roleId)
            const localVarPath = `/groups/{guildId}/group_roles/{roleId}`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)))
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupRoles: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('getGroupRoles', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}/group_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {GroupLinkedRole} [groupLinkedRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupRole: async (guildId: string, groupLinkedRole?: GroupLinkedRole, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('updateGroupRole', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}/group_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupLinkedRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupUsersApi - functional programming interface
 * @export
 */
export const GroupUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroupRole(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupLinkedRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroupRole(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupUsersApi.createGroupRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroupRole(guildId: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroupRole(guildId, roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupUsersApi.deleteGroupRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupRoles(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroupRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupRoles(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupUsersApi.getGroupRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {GroupLinkedRole} [groupLinkedRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroupRole(guildId: string, groupLinkedRole?: GroupLinkedRole, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroupRole(guildId, groupLinkedRole, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupUsersApi.updateGroupRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GroupUsersApi - factory interface
 * @export
 */
export const GroupUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupRole(guildId: string, options?: any): AxiosPromise<GroupLinkedRole> {
            return localVarFp.createGroupRole(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupRole(guildId: string, roleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroupRole(guildId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupRoles(guildId: string, options?: any): AxiosPromise<GetGroupRolesResponse> {
            return localVarFp.getGroupRoles(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {GroupLinkedRole} [groupLinkedRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupRole(guildId: string, groupLinkedRole?: GroupLinkedRole, options?: any): AxiosPromise<void> {
            return localVarFp.updateGroupRole(guildId, groupLinkedRole, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupUsersApi - object-oriented interface
 * @export
 * @class GroupUsersApi
 * @extends {BaseAPI}
 */
export class GroupUsersApi extends BaseAPI {
    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupUsersApi
     */
    public createGroupRole(guildId: string, options?: RawAxiosRequestConfig) {
        return GroupUsersApiFp(this.configuration).createGroupRole(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupUsersApi
     */
    public deleteGroupRole(guildId: string, roleId: string, options?: RawAxiosRequestConfig) {
        return GroupUsersApiFp(this.configuration).deleteGroupRole(guildId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupUsersApi
     */
    public getGroupRoles(guildId: string, options?: RawAxiosRequestConfig) {
        return GroupUsersApiFp(this.configuration).getGroupRoles(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {GroupLinkedRole} [groupLinkedRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupUsersApi
     */
    public updateGroupRole(guildId: string, groupLinkedRole?: GroupLinkedRole, options?: RawAxiosRequestConfig) {
        return GroupUsersApiFp(this.configuration).updateGroupRole(guildId, groupLinkedRole, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupRole: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('createGroupRole', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}/group_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupRole: async (guildId: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('deleteGroupRole', 'guildId', guildId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteGroupRole', 'roleId', roleId)
            const localVarPath = `/groups/{guildId}/group_roles/{roleId}`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)))
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('getGroup', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupRoles: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('getGroupRoles', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}/group_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {LinkGroupRequest} [linkGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkGroup: async (guildId: string, linkGroupRequest?: LinkGroupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('linkGroup', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}/link/start`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {LinkGroupRequest} [linkGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkGroupCheck: async (guildId: string, linkGroupRequest?: LinkGroupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('linkGroupCheck', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}/link/check`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {LinkGroupRequest} [linkGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkGroupFinish: async (guildId: string, linkGroupRequest?: LinkGroupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('linkGroupFinish', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}/link/join`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkGroup: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('unlinkGroup', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGroupRequest} [updateGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (guildId: string, updateGroupRequest?: UpdateGroupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('updateGroup', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {GroupLinkedRole} [groupLinkedRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupRole: async (guildId: string, groupLinkedRole?: GroupLinkedRole, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('updateGroupRole', 'guildId', guildId)
            const localVarPath = `/groups/{guildId}/group_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupLinkedRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroupRole(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupLinkedRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroupRole(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.createGroupRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroupRole(guildId: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroupRole(guildId, roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.deleteGroupRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroup(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroup(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.getGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupRoles(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroupRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupRoles(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.getGroupRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {LinkGroupRequest} [linkGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkGroup(guildId: string, linkGroupRequest?: LinkGroupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartLinkGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkGroup(guildId, linkGroupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.linkGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {LinkGroupRequest} [linkGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkGroupCheck(guildId: string, linkGroupRequest?: LinkGroupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckLinkGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkGroupCheck(guildId, linkGroupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.linkGroupCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {LinkGroupRequest} [linkGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkGroupFinish(guildId: string, linkGroupRequest?: LinkGroupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinishLinkGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkGroupFinish(guildId, linkGroupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.linkGroupFinish']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkGroup(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkGroup(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.unlinkGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGroupRequest} [updateGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(guildId: string, updateGroupRequest?: UpdateGroupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGroupRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(guildId, updateGroupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.updateGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {GroupLinkedRole} [groupLinkedRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroupRole(guildId: string, groupLinkedRole?: GroupLinkedRole, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroupRole(guildId, groupLinkedRole, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupsApi.updateGroupRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupRole(guildId: string, options?: any): AxiosPromise<GroupLinkedRole> {
            return localVarFp.createGroupRole(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupRole(guildId: string, roleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroupRole(guildId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(guildId: string, options?: any): AxiosPromise<GetGroupResponse> {
            return localVarFp.getGroup(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupRoles(guildId: string, options?: any): AxiosPromise<GetGroupRolesResponse> {
            return localVarFp.getGroupRoles(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {LinkGroupRequest} [linkGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkGroup(guildId: string, linkGroupRequest?: LinkGroupRequest, options?: any): AxiosPromise<StartLinkGroupResponse> {
            return localVarFp.linkGroup(guildId, linkGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {LinkGroupRequest} [linkGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkGroupCheck(guildId: string, linkGroupRequest?: LinkGroupRequest, options?: any): AxiosPromise<CheckLinkGroupResponse> {
            return localVarFp.linkGroupCheck(guildId, linkGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {LinkGroupRequest} [linkGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkGroupFinish(guildId: string, linkGroupRequest?: LinkGroupRequest, options?: any): AxiosPromise<FinishLinkGroupResponse> {
            return localVarFp.linkGroupFinish(guildId, linkGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkGroup(guildId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlinkGroup(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGroupRequest} [updateGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(guildId: string, updateGroupRequest?: UpdateGroupRequest, options?: any): AxiosPromise<UpdateGroupRequest> {
            return localVarFp.updateGroup(guildId, updateGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {GroupLinkedRole} [groupLinkedRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupRole(guildId: string, groupLinkedRole?: GroupLinkedRole, options?: any): AxiosPromise<void> {
            return localVarFp.updateGroupRole(guildId, groupLinkedRole, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public createGroupRole(guildId: string, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).createGroupRole(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public deleteGroupRole(guildId: string, roleId: string, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).deleteGroupRole(guildId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public getGroup(guildId: string, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).getGroup(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public getGroupRoles(guildId: string, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).getGroupRoles(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {LinkGroupRequest} [linkGroupRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public linkGroup(guildId: string, linkGroupRequest?: LinkGroupRequest, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).linkGroup(guildId, linkGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {LinkGroupRequest} [linkGroupRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public linkGroupCheck(guildId: string, linkGroupRequest?: LinkGroupRequest, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).linkGroupCheck(guildId, linkGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {LinkGroupRequest} [linkGroupRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public linkGroupFinish(guildId: string, linkGroupRequest?: LinkGroupRequest, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).linkGroupFinish(guildId, linkGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public unlinkGroup(guildId: string, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).unlinkGroup(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {UpdateGroupRequest} [updateGroupRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public updateGroup(guildId: string, updateGroupRequest?: UpdateGroupRequest, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).updateGroup(guildId, updateGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {GroupLinkedRole} [groupLinkedRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public updateGroupRole(guildId: string, groupLinkedRole?: GroupLinkedRole, options?: RawAxiosRequestConfig) {
        return GroupsApiFp(this.configuration).updateGroupRole(guildId, groupLinkedRole, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GuestUsersApi - axios parameter creator
 * @export
 */
export const GuestUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGuestRole: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('createGuestRole', 'guildId', guildId)
            const localVarPath = `/guilds/{guildId}/guest_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} guestRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestRole: async (guildId: string, guestRoleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('deleteGuestRole', 'guildId', guildId)
            // verify required parameter 'guestRoleId' is not null or undefined
            assertParamExists('deleteGuestRole', 'guestRoleId', guestRoleId)
            const localVarPath = `/guilds/{guildId}/guest_roles/{guestRoleId}`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)))
                .replace(`{${"guestRoleId"}}`, encodeURIComponent(String(guestRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestRoles: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('getGuestRoles', 'guildId', guildId)
            const localVarPath = `/guilds/{guildId}/guest_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGuestRole} [updateGuestRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuestRole: async (guildId: string, updateGuestRole?: UpdateGuestRole, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('updateGuestRole', 'guildId', guildId)
            const localVarPath = `/guilds/{guildId}/guest_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGuestRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuestUsersApi - functional programming interface
 * @export
 */
export const GuestUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuestUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGuestRole(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGuestRole(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestUsersApi.createGuestRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} guestRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuestRole(guildId: string, guestRoleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuestRole(guildId, guestRoleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestUsersApi.deleteGuestRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuestRoles(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestRoles(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestUsersApi.getGuestRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGuestRole} [updateGuestRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGuestRole(guildId: string, updateGuestRole?: UpdateGuestRole, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuestRole(guildId, updateGuestRole, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestUsersApi.updateGuestRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GuestUsersApi - factory interface
 * @export
 */
export const GuestUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuestUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGuestRole(guildId: string, options?: any): AxiosPromise<GetGuestRolesResponse> {
            return localVarFp.createGuestRole(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} guestRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestRole(guildId: string, guestRoleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGuestRole(guildId, guestRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestRoles(guildId: string, options?: any): AxiosPromise<GetGuestRolesResponse> {
            return localVarFp.getGuestRoles(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGuestRole} [updateGuestRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuestRole(guildId: string, updateGuestRole?: UpdateGuestRole, options?: any): AxiosPromise<void> {
            return localVarFp.updateGuestRole(guildId, updateGuestRole, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuestUsersApi - object-oriented interface
 * @export
 * @class GuestUsersApi
 * @extends {BaseAPI}
 */
export class GuestUsersApi extends BaseAPI {
    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestUsersApi
     */
    public createGuestRole(guildId: string, options?: RawAxiosRequestConfig) {
        return GuestUsersApiFp(this.configuration).createGuestRole(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {string} guestRoleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestUsersApi
     */
    public deleteGuestRole(guildId: string, guestRoleId: string, options?: RawAxiosRequestConfig) {
        return GuestUsersApiFp(this.configuration).deleteGuestRole(guildId, guestRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestUsersApi
     */
    public getGuestRoles(guildId: string, options?: RawAxiosRequestConfig) {
        return GuestUsersApiFp(this.configuration).getGuestRoles(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {UpdateGuestRole} [updateGuestRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestUsersApi
     */
    public updateGuestRole(guildId: string, updateGuestRole?: UpdateGuestRole, options?: RawAxiosRequestConfig) {
        return GuestUsersApiFp(this.configuration).updateGuestRole(guildId, updateGuestRole, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GuideApi - axios parameter creator
 * @export
 */
export const GuideApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuide: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/guide`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuideApi - functional programming interface
 * @export
 */
export const GuideApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuideApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuide(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuide(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuideApi.getGuide']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GuideApi - factory interface
 * @export
 */
export const GuideApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuideApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuide(options?: any): AxiosPromise<void> {
            return localVarFp.getGuide(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuideApi - object-oriented interface
 * @export
 * @class GuideApi
 * @extends {BaseAPI}
 */
export class GuideApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideApi
     */
    public getGuide(options?: RawAxiosRequestConfig) {
        return GuideApiFp(this.configuration).getGuide(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GuildsApi - axios parameter creator
 * @export
 */
export const GuildsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGuestRole: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('createGuestRole', 'guildId', guildId)
            const localVarPath = `/guilds/{guildId}/guest_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} guestRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestRole: async (guildId: string, guestRoleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('deleteGuestRole', 'guildId', guildId)
            // verify required parameter 'guestRoleId' is not null or undefined
            assertParamExists('deleteGuestRole', 'guestRoleId', guestRoleId)
            const localVarPath = `/guilds/{guildId}/guest_roles/{guestRoleId}`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)))
                .replace(`{${"guestRoleId"}}`, encodeURIComponent(String(guestRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestRoles: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('getGuestRoles', 'guildId', guildId)
            const localVarPath = `/guilds/{guildId}/guest_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuild: async (guildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('getGuild', 'guildId', guildId)
            const localVarPath = `/guilds/{guildId}`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuildList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/guilds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGuestRole} [updateGuestRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuestRole: async (guildId: string, updateGuestRole?: UpdateGuestRole, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('updateGuestRole', 'guildId', guildId)
            const localVarPath = `/guilds/{guildId}/guest_roles`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGuestRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGuildRequest} [updateGuildRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuild: async (guildId: string, updateGuildRequest?: UpdateGuildRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guildId' is not null or undefined
            assertParamExists('updateGuild', 'guildId', guildId)
            const localVarPath = `/guilds/{guildId}`
                .replace(`{${"guildId"}}`, encodeURIComponent(String(guildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGuildRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuildsApi - functional programming interface
 * @export
 */
export const GuildsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuildsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGuestRole(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGuestRole(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuildsApi.createGuestRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} guestRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuestRole(guildId: string, guestRoleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuestRole(guildId, guestRoleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuildsApi.deleteGuestRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuestRoles(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestRoles(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuildsApi.getGuestRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuild(guildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Guild>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuild(guildId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuildsApi.getGuild']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuildList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuildsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuildList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuildsApi.getGuildList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGuestRole} [updateGuestRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGuestRole(guildId: string, updateGuestRole?: UpdateGuestRole, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuestRole(guildId, updateGuestRole, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuildsApi.updateGuestRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGuildRequest} [updateGuildRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGuild(guildId: string, updateGuildRequest?: UpdateGuildRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuild(guildId, updateGuildRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuildsApi.updateGuild']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GuildsApi - factory interface
 * @export
 */
export const GuildsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuildsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGuestRole(guildId: string, options?: any): AxiosPromise<GetGuestRolesResponse> {
            return localVarFp.createGuestRole(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {string} guestRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestRole(guildId: string, guestRoleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGuestRole(guildId, guestRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestRoles(guildId: string, options?: any): AxiosPromise<GetGuestRolesResponse> {
            return localVarFp.getGuestRoles(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuild(guildId: string, options?: any): AxiosPromise<Guild> {
            return localVarFp.getGuild(guildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuildList(options?: any): AxiosPromise<GetGuildsListResponse> {
            return localVarFp.getGuildList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGuestRole} [updateGuestRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuestRole(guildId: string, updateGuestRole?: UpdateGuestRole, options?: any): AxiosPromise<void> {
            return localVarFp.updateGuestRole(guildId, updateGuestRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guildId 
         * @param {UpdateGuildRequest} [updateGuildRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuild(guildId: string, updateGuildRequest?: UpdateGuildRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateGuild(guildId, updateGuildRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuildsApi - object-oriented interface
 * @export
 * @class GuildsApi
 * @extends {BaseAPI}
 */
export class GuildsApi extends BaseAPI {
    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApi
     */
    public createGuestRole(guildId: string, options?: RawAxiosRequestConfig) {
        return GuildsApiFp(this.configuration).createGuestRole(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {string} guestRoleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApi
     */
    public deleteGuestRole(guildId: string, guestRoleId: string, options?: RawAxiosRequestConfig) {
        return GuildsApiFp(this.configuration).deleteGuestRole(guildId, guestRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApi
     */
    public getGuestRoles(guildId: string, options?: RawAxiosRequestConfig) {
        return GuildsApiFp(this.configuration).getGuestRoles(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApi
     */
    public getGuild(guildId: string, options?: RawAxiosRequestConfig) {
        return GuildsApiFp(this.configuration).getGuild(guildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApi
     */
    public getGuildList(options?: RawAxiosRequestConfig) {
        return GuildsApiFp(this.configuration).getGuildList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {UpdateGuestRole} [updateGuestRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApi
     */
    public updateGuestRole(guildId: string, updateGuestRole?: UpdateGuestRole, options?: RawAxiosRequestConfig) {
        return GuildsApiFp(this.configuration).updateGuestRole(guildId, updateGuestRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guildId 
     * @param {UpdateGuildRequest} [updateGuildRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApi
     */
    public updateGuild(guildId: string, updateGuildRequest?: UpdateGuildRequest, options?: RawAxiosRequestConfig) {
        return GuildsApiFp(this.configuration).updateGuild(guildId, updateGuildRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [vrcId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUser: async (vrcId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (vrcId !== undefined) {
                localVarQueryParameter['vrcId'] = vrcId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [vrcId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkUser: async (vrcId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (vrcId !== undefined) {
                localVarQueryParameter['vrcId'] = vrcId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLink: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetGroupInvites: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/group/reset_invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (query?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/unlink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [vrcId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUser: async (vrcId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (vrcId !== undefined) {
                localVarQueryParameter['vrcId'] = vrcId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [vrcId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUser(vrcId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUser(vrcId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.checkUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [vrcId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkUser(vrcId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkUser(vrcId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.linkUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshLink(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshLink(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.refreshLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetGroupInvites(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetGroupInvites(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.resetGroupInvites']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(query?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VRChatUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsers(query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.searchUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.unlinkUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [vrcId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyUser(vrcId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyUser(vrcId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.verifyUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [vrcId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUser(vrcId?: string, options?: any): AxiosPromise<LinkingResponse> {
            return localVarFp.checkUser(vrcId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<ProfileResponse> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<User> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [vrcId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkUser(vrcId?: string, options?: any): AxiosPromise<LinkingResponse> {
            return localVarFp.linkUser(vrcId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLink(options?: any): AxiosPromise<void> {
            return localVarFp.refreshLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetGroupInvites(options?: any): AxiosPromise<void> {
            return localVarFp.resetGroupInvites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(query?: string, options?: any): AxiosPromise<Array<VRChatUser>> {
            return localVarFp.searchUsers(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkUser(options?: any): AxiosPromise<void> {
            return localVarFp.unlinkUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [vrcId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUser(vrcId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.verifyUser(vrcId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {string} [vrcId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public checkUser(vrcId?: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).checkUser(vrcId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getProfile(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [vrcId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public linkUser(vrcId?: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).linkUser(vrcId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public refreshLink(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).refreshLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetGroupInvites(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetGroupInvites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public searchUsers(query?: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).searchUsers(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public unlinkUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).unlinkUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [vrcId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public verifyUser(vrcId?: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).verifyUser(vrcId, options).then((request) => request(this.axios, this.basePath));
    }
}




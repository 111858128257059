import React from 'react';
import './DiscordSettings.css';
import SelectPickerItem from './SelectPickerItem';
import { guildsApi } from '../api/helpers/guilds-api';
import { GuestUsersPicker } from './GuestUsersPicker';
import { EncodeRole, Guild } from '../api';

interface ServerDetailsProps {
    serverId: string;
}

const DiscordSettings: React.FC<ServerDetailsProps> = (server) => {
    const [serverDetails, setServerDetails] = React.useState<Guild>({} as Guild);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        setIsLoading(true);
        guildsApi
            .getGuild(server.serverId)
            .then((response) => {
                setServerDetails(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [server.serverId]);

    return isLoading ? (
        <div className="server-details">Loading..</div>
    ) : (
        <div className="server-details">
            <div className="icon">
                <img src={serverDetails.icon!} alt="Server Icon" />
            </div>
            <h1>{serverDetails.name}</h1>
            <div className="member-info">
                <div>
                    <p>Members: {serverDetails.members}</p>
                </div>
                <div>
                    <p>Linked Members: {serverDetails.linkedMembers}</p>
                </div>
            </div>
            <div className="data-urls">
                <button className="btn btn-primary">Data URL (Untrusted URLs)</button>
                <button className="btn btn-primary">Data URL (Trusted URL)</button>
            </div>

            <div className={'setting-title'}>
                <h1>Settings</h1>
            </div>

            <div className="server-toggles list-group">
                <SelectPickerItem
                    name={'Select Roles To Encode'}
                    modalTitle={'Select Roles'}
                    buttonName={'Select Roles'}
                    options={
                        // @ts-ignore
                        serverDetails.roles.map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                                color: role.color,
                                position: role.position,
                            };
                        })
                    }
                    selectedOptions={serverDetails.encRoleList.map((role) => {
                        return {
                            value: role.id,
                            label: role.name,
                            color: role.color,
                            position: role.position,
                        };
                    })}
                    // @ts-ignore
                    onChange={(value) => {
                        serverDetails.encRoleList = value.map((role) => {
                            return {
                                id: role.value,
                                name: role.label,
                                color: role.color,
                                position: role.position,
                            };
                        });
                        guildsApi.updateGuild(serverDetails.id, {
                            encRoleList: serverDetails.encRoleList.map((role: { id: string }) => {
                                return role.id;
                            }),
                        });
                        setServerDetails({ ...serverDetails });
                    }}
                />
                <SelectPickerItem
                    name={'Select Roles To Always Encode'}
                    modalTitle={'Select Roles'}
                    buttonName={'Select Roles'}
                    options={
                        // @ts-ignore
                        serverDetails.roles.map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                                color: role.color,
                                position: role.position,
                            };
                        })
                    }
                    selectedOptions={
                        // @ts-ignore
                        serverDetails.alwaysEncRoles.map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                                color: role.color,
                                position: role.position,
                            };
                        })
                    }
                    // @ts-ignore
                    onChange={(value) => {
                        serverDetails.alwaysEncRoles = value.map((role) => {
                            return {
                                id: role.value,
                                name: role.label,
                                color: role.color,
                                position: role.position,
                            };
                        });
                        guildsApi.updateGuild(serverDetails.id, {
                            alwaysEncRoles: serverDetails.alwaysEncRoles.map((role: { id: string }) => {
                                return role.id;
                            }),
                        });
                        setServerDetails({ ...serverDetails });
                    }}
                />
                <GuestUsersPicker guild={serverDetails} />
            </div>
        </div>
    );
};

export default DiscordSettings;

export const CustomButton = (props: {
    name?: string;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
}) => {
    return (
        <button className={'btn ' + props.className} color="primary" onClick={props.onClick} style={props.style}>
            {props.name}
        </button>
    );
};

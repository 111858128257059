import React, { createContext, useContext, useState, ReactNode } from 'react';
import { AuthApi, User } from '../api';
import { usersApi } from '../api/helpers/users-api';

const authApi = new AuthApi();

interface AuthContextType {
    isLoggedIn: boolean;
    isLoaded: boolean;
    userData: User;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState<any>();

    const fetchUser = async () => {
        try {
            const res = await usersApi.getUser();
            setUserData(res.data);
            if (res.data.username) {
                setIsLoggedIn(true);
            }
            setIsLoaded(true);
        } catch (e) {
            console.error(e);

            setIsLoggedIn(false);
            setUserData(null);
            setIsLoaded(true);
        }
    };

    React.useEffect(() => {
        fetchUser();
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, isLoaded, userData }}>
            {isLoaded ? children : null}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

import {Modal} from "react-bootstrap";
import React from "react";
import "./PopupModal.css";

interface PopupModalProps {
    show: boolean;
    onClose: () => void;
    children: React.ReactNode;
    title: string;
}
export const PopupModal = ({show, onClose, children, title}: PopupModalProps) => {
    const handleClose = () => {
        onClose();
    }

    return (
        <div className={"modal show"}
             style={{display: 'block', position: 'initial'}}>
            <Modal show={show} onHide={handleClose} contentClassName={"bg-dark"}>
                <Modal.Header>
                    <div className={"modal-header-div"}>
                        <h1 className={"modal-header-title"}>
                            {title}
                        </h1></div>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-container">
                        {children}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
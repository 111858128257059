import { StylesConfig } from 'react-select';
import { OptionType } from './GuestUsersPicker';

export const darkThemeSelect: StylesConfig<OptionType> = {
    control: (styles) => ({
        ...styles,
        backgroundColor: '#2C2C2C',
        borderColor: '#444',
        color: '#FFFFFF',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#666',
        },
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: '#2C2C2C',
        overflowX: 'hidden', // Prevent horizontal scrolling
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected ? '#444' : isFocused ? '#333' : '#2C2C2C',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#444',
        },
    }),
    singleValue: (styles) => ({
        ...styles,
        color: '#FFFFFF',
    }),
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: '#444',
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#FFFFFF',
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: '#FF6666',
        '&:hover': {
            backgroundColor: '#FF0000',
            color: '#FFFFFF',
        },
    }),
    input: (styles) => ({
        ...styles,
        color: '#FFFFFF', // Make the typed text white
    }),
};

import React from 'react';
import { AuthApi } from '../api';

const authApi = new AuthApi();

const Login: React.FC = () => {
    const login = async () => {
        const res = await authApi.login();
        window.location.href = res.data.redirectUri;
    };

    React.useEffect(() => {
        login();
    }, []);

    return <div>Redirecting to Discord for login...</div>;
};

export default Login;

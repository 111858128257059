import React from 'react';
import {AuthApi} from "../api";


const authApi = new AuthApi();

const Logout: React.FC = () => {
    const logout = async () => {
        await authApi.logout();
        window.location.href = "/"
    }

    React.useEffect(() => {
        logout();
    }, []);


    return (
        <div>
            Logging out...
        </div>
    );
}

export default Logout;

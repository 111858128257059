import React from 'react';
import { useAuth } from './AuthProvider';
import './Profile.css';
import { ProfileResponse } from '../api';
import { usersApi } from '../api/helpers/users-api';
import { CustomButton } from '../components/CustomButton';
import { AccountLinking } from '../components/AccountLinking';

const Profile: React.FC = () => {
    const { userData } = useAuth();
    const [profileData, setProfileData] = React.useState<ProfileResponse | null>(null);

    React.useEffect(() => {
        handleRefresh();
    }, []);

    function handleRefresh() {
        usersApi.getProfile().then((response) => {
            setProfileData(response.data);
        });
    }

    return (
        <div className="profile">
            {/* Profile Header */}
            <div className="profile-header">
                <img className="profile-avatar" src={userData.avatar ?? ''} alt="User Avatar" />
                {profileData?.linkData.isVerified ? (
                    <span className="badge verified-badge">Verified</span>
                ) : (
                    <span className="badge not-verified-badge">Not-Verified</span>
                )}
                <div className="profile-username-wrapper">
                    <h2 className="profile-username">{userData.username}</h2>
                </div>
                {profileData && <AccountLinking profileData={profileData} refresh={handleRefresh} />}
            </div>

            {/* User Details Section */}
            <div className="profile-section profile-details">
                <h3 className="section-title">User Details</h3>
                <div className="details-list">
                    <div className="detail-item">
                        <span className="detail-label">ID:</span>
                        <span className="detail-value">{profileData?.user.id || 'N/A'}</span>
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">VRC ID:</span>
                        <span className="detail-value">{profileData?.linkData.id || 'N/A'}</span>
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">VRC Name:</span>
                        <span className="detail-value">{profileData?.linkData.vrchatName || 'N/A'} </span>
                    </div>
                </div>
            </div>

            <hr />

            {/* Discord Servers Section */}
            <div className="profile-section">
                <h3 className="section-title">Discord Servers</h3>
                <p>{profileData?.guilds.length || 0} servers</p>
                <div className="profile-servers">
                    {profileData?.guilds.map((guild) => (
                        <div key={guild.id} className="profile-server">
                            <img src={guild.icon ?? ''} alt="" className="server-icon" />
                            <h4>{guild.name}</h4>
                        </div>
                    ))}
                </div>
            </div>

            <hr />

            {/* VRChat Groups Section */}
            <div className="profile-section">
                <h3 className="section-title">VRChat Groups</h3>
                <p>{profileData?.groups.length || 0} groups</p>
                <div className="profile-servers">
                    {profileData?.groups.map((group) => (
                        <div key={group.id} className="profile-server">
                            <img src={group.icon ?? ''} alt="" className="server-icon" />
                            <h4>{group.name}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Profile;
